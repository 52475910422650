import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    options: {
      customProperties: true,
    },
    themes: {
      light: {
        primary: '#01499A',
        secondary: '#459CFE',
        accent: '#82B1FF',
        error: '#D84D46',
        info: '#459CFE',
        success: '#58AB68',
        warning: '#EEBC4A',
      },
    },
  },
});
